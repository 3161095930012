<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div v-for="msg in apierror.messages" :key="msg">
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="item.auc_id"
                  hide-details="auto"
                  label="ID"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.auc_code"
                  hide-details="auto"
                  label="商品コード"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.auc_name_ja"
                  hide-details="auto"
                  label="入札商品名"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="item.pellet_id"
                  hide-details="auto"
                  label="Pellet"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                align-self="end"
              >
                <NumberInput
                  v-model="item.auc_weight"
                  label="商品重量"
                  :readonly="true"
                  suffix="KG"
                ></NumberInput>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="item.auc_ssd"
                  hide-details="auto"
                  label="開始日"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.auc_sed"
                  hide-details="auto"
                  label="締切日"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="my-6" v-html="item.auc_ovv_ja"></div>
            <v-row>
              <v-col
                v-for="(url, index) in fileUrls"
                :key="index"
                cols="4"
                md="2"
              >
                <v-card height="100px">
                  <v-img
                    :src="url"
                    height="100px"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="deleteItem(item)"
          >
            YES
          </v-btn>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus, mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import NumberInput from '@/components/Input/NumberInput.vue'

// import ImageSelected from '@/components/ImageSelectedComponent.vue'

export default {
  components: {
    NumberInput,

    // ImageSelected,
  },
  props: ['icon', 'id'],
  data: () => ({
    icons: {
      mdiPlus,
      mdiCalendar,
    },
    submitStatus: false,
    dialog: false,
    fileUrls: [],
    item: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('auctionStore', ['selectedAuction']),
  },
  methods: {
    ...mapActions('auctionStore', ['deleteAuction', 'loadAuction']),
    ...mapMutations('app', ['setOverlayStatus']),
    deleteItem(item) {
      this.apierror.messages = []

      this.submitStatus = true
      this.deleteAuction(item.auc_id)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.messages = error.response.data.message

          // console.log('apierror', this.apierror)
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
    openForm() {
      this.setOverlayStatus(true)
      this.loadAuction(this.id)
        .then(() => {
          this.item = {
            ...this.selectedAuction,
          }

          // this.item.bid_open_dt = this.selectedBid.bid_open_dt.substring(0, 10)
          // this.item.bid_comp_dt = this.selectedBid.bid_comp_dt.substring(0, 10)
          // this.fileUrls = this.selectedBid.reserve_images.map(i => i.reserve_image)
        })
        .catch(error => {
          this.apierror.status = 'error'
          this.apierror.messages = error.response.data.message

          // console.log('apierror', this.apierror)
        })
        .finally(() => {
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
    closeForm() {
      this.$emit('closed')
      this.apierror.messages = []
      this.dialog = false
      this.item = {
      }
    },
  },
}
</script>
