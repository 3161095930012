var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"produce-report"}},[_c('v-card',[_c('v-card-title',[_vm._v("入札商品(Auction)一覧 ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.pelletIds,"placeholder":"Pellet","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.pellet_id),callback:function ($$v) {_vm.pellet_id=$$v},expression:"pellet_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.auctionCodes,"placeholder":"入札商品","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.auc_code),callback:function ($$v) {_vm.auc_code=$$v},expression:"auc_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"入札開始日From","dense":"","hide-details":"","outlined":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateStartMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"入札終了日To","dense":"","outlined":"","hide-details":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","to":{ name: 'auction-create' }}},[_c('span',[_vm._v("NEW")])]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.auctionList,"search":_vm.searchQuery},scopedSlots:_vm._u([{key:"item.auc_code",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"18%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({attrs:{"for":"reserve_id"}},'label',attrs,false),on),[_vm._v(_vm._s(item.auc_code))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.auc_name_ja))])])]}},{key:"item.auc_weight",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.auc_weight ? _vm.formatData(item.auc_weight) : '0'))])]}},{key:"item.auc_min_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" ￥"+_vm._s(_vm.formatData(item.auc_min_amount))+" ")]}},{key:"item.auc_min_amount_usd",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.formatData(item.auc_min_amount_usd))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('auction-preview',{staticClass:"py-2 pl-1",attrs:{"id":item.auc_id},on:{"closed":_vm.loadData}}),_c('div',{staticClass:"py-2 pl-1"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('delete-form',{staticClass:"py-2 pl-1",attrs:{"id":item.auc_id,"icon":_vm.icons.mdiDelete},on:{"closed":_vm.loadData}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }